<template>
    <el-dialog
        v-model="showModal"
        class="detail-modal"
        append-to-body
        center
        :show-close="false"
        :close-on-click-modal="true"
        @close="handleCloseModal"
        :width="isMobileDevice() ? '90%' : '1024px'"
        @open="handleOpenModal"
        @closed="handleClosedModal">
        <template #header="{ close }">
            <div class="detail-modal__header">
                <div v-if="image"
                     class="detail-modal__image"
                     :style="`background-image: url(${image});`">
                </div>
                <div class="detail-modal__rectangle"></div>
                <div class="detail-modal__close-icon" @click="close"><Close /></div>
            </div>
        </template>
        <div class="detail-modal__body">
            <div v-if="title" class="detail-modal__title">
                {{ title }}
            </div>
            <div v-if="content"
                 class="detail-modal__content">
                <el-scrollbar ref="scrollbarRef"
                              class="detail-modal__content-scrollbar"
                              always
                              :max-height="450">
                    <component :is="content" />
                    <div style="height: 70px; width: 100%;"></div>
                </el-scrollbar>
                <div class="detail-modal__content-footer"></div>
            </div>
        </div>

    </el-dialog>

</template>

<script>
import { ElDialog, ElScrollbar } from 'element-plus';
import Close from '@/components/Partials/Icons/Close.vue';

export default {
    name: 'DetailModal',
    components: {
        Close,
        ElDialog,
        ElScrollbar
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        image: {
            type: String,
            default: null
        },
        imageSize: {
            type: String,
            default: null
        },
        imagePosition: {
            type: String,
            default: null
        },
        content: {
            type: Function
        }
    },
    emits: [
        'closeModal',
        'closedModal'
    ],
    data () {
        return {
            showModal: false
        };
    },
    watch: {
        show (value) {
            this.showModal = value;
        }
    },
    mounted () {

    },
    methods: {
        handleCloseModal () {
            this.$emit('closeModal');
        },
        handleClosedModal () {
            this.$emit('closedModal');
        },
        handleOpenModal () {

        }
    }
};
</script>
<style scoped lang="scss">
.detail-modal {
    //position: absolute;
    //left: 50%;
    //top: 50%;
    //transform: translate(-50%, -50%);

    &__rectangle {
        border-top-left-radius: var(--el-dialog-border-radius);
        border-top-right-radius:  var(--el-dialog-border-radius);
        opacity: 0.3;
        position: absolute;
        background-image: url(@/assets/blue_bg.jpg);
        background-position: top center;
        background-size: cover;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    &__image {
        background-position: center -20px;
        background-size: 30%;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        z-index: 1;

        @media (max-width: 768px) {
            background-size: 85%;
        }
    }
    &__header {
        border-top-left-radius: var(--el-dialog-border-radius);
        border-top-right-radius:  var(--el-dialog-border-radius);

        background: #458BE8 linear-gradient(135deg, #2AFADF 0%, #4C83FF 100%);

        position: relative;
        height: 175px;
    }
    &__close-icon {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: #FFFFFF;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        color: #566379;
        padding: 3px;
        z-index: 2;
        cursor: pointer;
    }
    &__body {
        padding: 32px 20px 20px;
    }
    &__title {
        font-size: 24px;
        font-weight: 600;
        color: #101010;
    }
    &__content {
        margin: 20px 0 0 0;
        padding: 0 5px 0 0;
        font-weight: 400;
        font-size: 18px;
        position: relative;
    }
    &__content-scrollbar {
        padding: 0 15px 0 0;

    }
    &__content-footer {
        position: absolute;
        width: 100%;
        height: 70px;
        bottom: -1px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }
}
</style>
