import { createApp } from 'vue';
import App from './App.vue';
import 'normalize.css';
import './sass/fonts.scss';
import './main.scss';
const app = createApp(App);

app.mixin({
    methods: {
        goToAnchor (anchor) {
            const url = location.href;
            location.href = '#' + anchor;
            history.replaceState(null, null, url);
        },
        isMobileDevice () {
            const toMatch = [
                /Android/i,
                /webOS/i,
                /iPhone/i,
                /iPad/i,
                /iPod/i,
                /BlackBerry/i,
                /Windows Phone/i
            ];

            return toMatch.some((toMatchItem) => {
                return navigator.userAgent.match(toMatchItem);
            });
        }
    }
});

app.mount('#app');

// window.addEventListener('DOMContentLoaded', (event) => {
//     app.mount('#nexta-landing');
// });
