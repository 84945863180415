<template>
    <svg width="120" height="23" viewBox="0 0 120 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H30V14.5C30 18.9183 26.4183 22.5 22 22.5H0V8.5Z" fill="#165BB6"/>
        <path d="M4 6.5C4 5.39543 4.89543 4.5 6 4.5H9V18.5H4V6.5Z" fill="white"/>
        <path d="M9 4.5C12.3137 4.5 15 7.18629 15 10.5L15 11.5L9 11.5L9 4.5Z" fill="url(#paint0_linear_5_29)"/>
        <path d="M21 18.5C17.6863 18.5 15 15.8137 15 12.5L15 11.5L21 11.5L21 18.5Z" fill="url(#paint1_linear_5_29)"/>
        <path d="M26 16.5C26 17.6046 25.1046 18.5 24 18.5L21 18.5L21 4.5L26 4.5L26 16.5Z" fill="white"/>
        <path d="M39 18.3629V11.4315V4.5H41.8499L48.2328 13.879V4.5H51.0241V18.3629H48.2523L41.7913 9.08182V18.3629H39Z" fill="#165BB6"/>
        <path d="M58.2585 18.5C57.1785 18.5 56.261 18.2846 55.5063 17.8538C54.7515 17.4231 54.1724 16.8161 53.769 16.0329C53.3656 15.2366 53.1639 14.3098 53.1639 13.2524C53.1639 12.1821 53.3721 11.2487 53.7885 10.4524C54.205 9.64312 54.7906 9.01002 55.5453 8.55315C56.3131 8.09627 57.2175 7.86783 58.2585 7.86783C59.0393 7.86783 59.7225 7.99837 60.3081 8.25944C60.9067 8.52051 61.4077 8.88601 61.8111 9.35594C62.2275 9.82587 62.5333 10.3741 62.7285 11.0007C62.9367 11.6142 63.0343 12.28 63.0213 12.9979C63.0213 13.1807 63.0148 13.3569 63.0018 13.5266C62.9888 13.6963 62.9628 13.866 62.9237 14.0357H55.8771C55.9162 14.4273 56.0268 14.7862 56.209 15.1126C56.3912 15.4389 56.6449 15.7 56.9702 15.8958C57.3086 16.0786 57.725 16.1699 58.2195 16.1699C58.5448 16.1699 58.8441 16.1373 59.1174 16.072C59.3907 15.9937 59.6249 15.8762 59.8201 15.7196C60.0153 15.5629 60.1519 15.3802 60.23 15.1713H62.9237C62.7546 15.9415 62.4357 16.5746 61.9673 17.0706C61.5118 17.5667 60.9588 17.9322 60.3081 18.1671C59.6574 18.389 58.9743 18.5 58.2585 18.5ZM55.9357 12.1168H60.4057C60.3927 11.7643 60.3016 11.438 60.1324 11.1378C59.9763 10.8245 59.7355 10.5765 59.4102 10.3937C59.0979 10.1979 58.6945 10.1 58.2 10.1C57.7055 10.1 57.2891 10.1979 56.9507 10.3937C56.6254 10.5895 56.3782 10.8441 56.209 11.1573C56.0528 11.4576 55.9617 11.7774 55.9357 12.1168Z" fill="#165BB6"/>
        <path d="M63.6348 18.3629L67.2264 13.0958L63.8105 8.04406H66.9922L69.0223 11.2944L71.0133 8.04406H74.0974L70.6619 13.1545L74.3316 18.3629H71.1499L68.9051 14.9755L66.6799 18.3629H63.6348Z" fill="#165BB6"/>
        <path d="M79.7106 18.5C78.5524 18.5 77.6936 18.1737 77.134 17.521C76.5875 16.8683 76.3142 15.9741 76.3142 14.8385V10.4916H74.7722V8.04406H76.3142V5.26364H79.0469V8.04406H82.1701V10.4916H79.0469V14.2706C79.0469 14.61 79.086 14.9037 79.1641 15.1517C79.2552 15.3998 79.3983 15.5956 79.5935 15.7392C79.8017 15.8697 80.0815 15.935 80.4328 15.935C80.7452 15.935 81.0054 15.8893 81.2136 15.7979C81.4348 15.6935 81.617 15.5825 81.7602 15.465L82.619 17.5797C82.3458 17.8016 82.04 17.9844 81.7016 18.128C81.3763 18.2585 81.0445 18.3499 80.7061 18.4021C80.3678 18.4674 80.0359 18.5 79.7106 18.5Z" fill="#165BB6"/>
        <path d="M87.0085 18.5C86.54 18.5 86.0846 18.4413 85.6421 18.3238C85.2127 18.1932 84.8288 17.9974 84.4905 17.7364C84.1521 17.4753 83.8854 17.149 83.6902 16.7573C83.495 16.3657 83.3974 15.9089 83.3974 15.3867C83.3974 14.6818 83.5405 14.1205 83.8268 13.7028C84.1131 13.272 84.497 12.9457 84.9785 12.7238C85.46 12.5019 86.013 12.3517 86.6376 12.2734C87.2753 12.1951 87.9324 12.1559 88.6091 12.1559H90.0926C90.0926 11.7774 90.021 11.4445 89.8779 11.1573C89.7478 10.8571 89.5461 10.6287 89.2728 10.472C88.9995 10.3023 88.6482 10.2175 88.2187 10.2175C87.9455 10.2175 87.6787 10.2566 87.4184 10.335C87.1712 10.4002 86.963 10.4981 86.7938 10.6287C86.6246 10.7592 86.514 10.9289 86.462 11.1378H83.6902C83.7683 10.5634 83.9504 10.0739 84.2367 9.66923C84.523 9.25151 84.8809 8.91212 85.3103 8.65105C85.7397 8.38998 86.2082 8.2007 86.7157 8.08322C87.2362 7.95268 87.7698 7.88741 88.3163 7.88741C89.8128 7.88741 90.9189 8.31818 91.6347 9.17972C92.3504 10.0282 92.7082 11.2357 92.7082 12.8021V18.3629H90.2488L90.2097 17.0315C89.8844 17.4883 89.5135 17.8212 89.0971 18.0301C88.6937 18.2389 88.3033 18.3695 87.9259 18.4217C87.5486 18.4739 87.2427 18.5 87.0085 18.5ZM87.6331 16.3266C88.0886 16.3266 88.505 16.2352 88.8824 16.0524C89.2598 15.8697 89.5591 15.6217 89.7803 15.3084C90.0015 14.9951 90.1121 14.6427 90.1121 14.251V13.9965H88.2383C87.965 13.9965 87.6982 14.0096 87.4379 14.0357C87.1907 14.0618 86.963 14.114 86.7548 14.1923C86.5596 14.2576 86.4034 14.3685 86.2863 14.5252C86.1692 14.6818 86.1106 14.8907 86.1106 15.1517C86.1106 15.4128 86.1757 15.6347 86.3058 15.8175C86.449 15.9872 86.6376 16.1177 86.8719 16.2091C87.1061 16.2874 87.3599 16.3266 87.6331 16.3266Z" fill="#165BB6"/>
        <path d="M97.4466 13.9182C96.0841 13.9182 94.9795 12.8136 94.9795 11.451H99.1686C100.531 11.451 101.636 12.5556 101.636 13.9182H97.4466Z" fill="#165BB6"/>
        <path d="M104.307 18.3629V7.2913C104.307 5.74971 105.557 4.5 107.098 4.5V18.3629H104.307Z" fill="#165BB6"/>
        <path d="M112.953 18.3629V7.10419H111.341C109.903 7.10419 108.737 5.93826 108.737 4.5H120C120 5.93826 118.834 7.10419 117.396 7.10419H115.764V18.3629H112.953Z" fill="#165BB6"/>
        <defs>
            <linearGradient id="paint0_linear_5_29" x1="14" y1="8" x2="8" y2="8" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="#165BB6"/>
            </linearGradient>
            <linearGradient id="paint1_linear_5_29" x1="16" y1="15" x2="22" y2="15" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="#165BB6"/>
            </linearGradient>
        </defs>
    </svg>

</template>

<script>
export default {
    name: 'LogoBlue'
};
</script>

<style scoped>

</style>
