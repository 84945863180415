<template>
    <div class="company-info">
        <div v-if="header" class="company-info__header">
            {{ header }}
        </div>
        <div class="company-info__items">
            <div class="company-info__item">
                <span class="company-info__label">Номер телефона:</span>
                <a href="tel:+79855602429"
                   class="company-info__value"
                   title="Номер телефона">+7 985 560-24-29</a>
            </div>
            <div class="company-info__item">
                <span class="company-info__label">Электронная почта:</span>
                <a href="mailto:info@nexta-it.com"
                   class="company-info__value"
                   title="Электронная почта">info@nexta-it.com</a>
            </div>
            <div class="company-info__item">
                <span class="company-info__label">ОГРН:</span>
                <span class="company-info__value">1217700254970</span>
            </div>
            <div class="company-info__item">
                <span class="company-info__label">ИНН:</span>
                <span class="company-info__value">7713482511</span>
            </div>
            <div class="company-info__item">
                <span class="company-info__label">КПП:</span>
                <span class="company-info__value">771301001</span>
            </div>
            <div class="company-info__item">
                <span class="company-info__label">Адрес местонахождения:</span>
                <span class="company-info__value">г. Москва, ш. Дмитровское, д. 100, стр.2, этаж 4, помещ. 2421</span>
            </div>
        </div>
        <div v-if="footer" class="company-info__footer">
           {{ footer }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'CompanyInfo',
    props: {
        header: {
            type: String
        },
        footer: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.company-info {
    &__items {
        display: flex;
        flex-direction: column;
        gap: 15px 0;
    }
    &__header {
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        margin: 0 0 20px 0;
    }
    &__footer {
        margin: 40px 0 0 0;
        color: rgb(255, 255, 255, 0.7);
    }
    &__item {
        line-height: 1.3;
    }
    &__label {
        color: rgb(255, 255, 255, 0.7);
    }
    &__value {
        margin: 0 0 0 5px;
        color: #FFFFFF;
    }
}

</style>
