<template>
    <div class="info-section">
        <div class="info-section__title" :class="`info-section__title_${titlePosition}`">
            <h2>{{ title }}</h2>
        </div>
        <div class="info-section__content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoSection',
    props: {
        title: {
            type: String
        },
        titlePosition: {
            type: String,
            default: 'left'
        }
    }
};
</script>

<style scoped lang="scss">
.info-section {
    padding: 0 24px;
    &__title {
        font-size: 44px;
        font-weight: 600;
        color: #101010;
        &_center {
            text-align: center;
        }
        &_left {
            text-align: left;
        }
    }
    &__content {
        margin: 40px 0 0 0;
    }
}
</style>
