<template>
    <div class="checkbox-field" :class="{ 'checkbox-field_error': showError }">
        <div class="checkbox-field__field">
            <input class="checkbox-field__native"
                   :value="modelValue"
                   :true-value="true"
                   :false-value="false"
                   type="checkbox" />
            <div class="checkbox-field__checkbox"
                 :class="{ 'checkbox-field__checkbox_checked': modelValue }"
                 @click="toggle">
                <Accept v-if="modelValue" />
            </div>
            <div class="checkbox-field__label">
                <slot name="label" />
            </div>
        </div>
        <div v-if="showError"
             class="checkbox-field__error">
            {{ error }}
        </div>
    </div>
</template>

<script>
import Accept from '@/components/Partials/Icons/Accept.vue';

export default {
    name: 'CheckboxField',
    components: { Accept },
    emits: [
        'update:modelValue'
    ],
    props: {
        modelValue: {
            type: Boolean
        },
        title: {
            type: String
        },
        size: {
            type: String,
            default: 'default' // default or large
        },
        showError: {
            type: Boolean,
            default: false
        },
        error: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {};
    },
    methods: {
        toggle () {
            if (this.disabled) {
                return;
            }
            const value = this.modelValue;
            this.$emit('update:modelValue', !value);
        }
    }
};
</script>

<style lang="scss">
.checkbox-field {
    &__label {
        & > a {
            color: rgba(255, 255, 255, 1);
            text-decoration: none;
        }
    }
}
</style>

<style scoped lang="scss">
.checkbox-field {

    &__native {
        visibility: hidden;
        z-index: -9999;
        position: absolute;
    }
    &__label {
        color: rgba(255, 255, 255, 1);
        font-size: 15px;
        font-weight: 300;
        line-height: 18px;
    }
    &__field {
        display: flex;
        gap: 0 15px;
        border-radius: 8px;
        border: 1px solid transparent;
        padding: 5px;
    }
    &__checkbox {
        cursor: pointer;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        background-color: rgba(250, 250, 251, 0.58);
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 4px;
        &_checked {
            background-color: rgba(40, 95, 235, 1);
            border: 1px solid rgba(40, 95, 235, 1);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__error {
        padding: 5px 0 0 2px;
        color: rgb(167 53 53 / 80%);
    }
    &_error {
        .checkbox-field__field {
            background-color: rgb(95 29 29 / 20%);
            border: 1px solid rgb(169 36 36);
        }

    }
}
</style>
