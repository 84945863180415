<template>
    <div class="card-wrapper"
         :style="blockStyles()">
        <div class="card-wrapper__rectangle"
             :style="rectangleStyles()">
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'CardWrapper',
    props: {
        maxHeight: {
            type: String
        },
        minHeight: {
            type: String
        }
    },
    methods: {
        blockStyles () {
            return {
                background: `linear-gradient(${this.getRandomInt(0, 270)}deg, #2AFADF 0%, #4C83FF 100%)`,
                minHeight: this.minHeight,
                maxHeight: this.maxHeight
            };
        },
        rectangleStyles () {
            return {
                transform: `rotate(${this.randomRectangleRotate()}deg)`
            };
        },
        randomRectangleRotate () {
            const rotates = [0, 180, 360];
            return rotates[Math.floor(Math.random() * rotates.length)];
        },
        getRandomInt (min, max) {
            const minCeiled = Math.ceil(min);
            const maxFloored = Math.floor(max);
            return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
        }
    }
};
</script>

<style scoped lang="scss">
.card-wrapper {
    border-radius: 12px;
    background-color: #458BE8;
    height: 700px;
    position: relative;
    overflow: hidden;
    &__rectangle {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0.35;
        background-image: url(@/assets/rectangle.jpg);
        background-repeat: no-repeat;
        background-size: cover;
    }
}
</style>
