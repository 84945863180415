<template>
    <svg class="spinner"
         xmlns="http://www.w3.org/2000/svg"
         height="20"
         viewBox="0 0 20 20"
         width="20">
        <path d="m10 3.5c-3.58985 0-6.5 2.91015-6.5 6.5 0 .4142-.33579.75-.75.75s-.75-.3358-.75-.75c0-4.41828 3.58172-8 8-8 4.4183 0 8 3.58172 8 8 0 4.4183-3.5817 8-8 8-.41421 0-.75-.3358-.75-.75s.33579-.75.75-.75c3.5899 0 6.5-2.9101 6.5-6.5 0-3.58985-2.9101-6.5-6.5-6.5z"
              :fill="color"/>
    </svg>
</template>

<script>
export default {
    name: 'Spinner',
    props: {
        color: {
            type: String,
            default: '#3d7bf8'
        }
    }
};
</script>

<style scoped lang="scss">

.spinner {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn)
    }
}
</style>
