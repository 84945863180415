<template>
    <svg width="280" height="322" viewBox="0 0 280 322" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M140.156 81L209.489 121L140.156 161L70.8223 121L140.156 81Z" stroke="#101010" stroke-miterlimit="10"/>
        <path d="M140.156 161L70.8223 121V201L140.156 241V161Z" stroke="#165BB6" stroke-miterlimit="10"/>
        <path d="M70.8223 121L1.66675 81V161L70.8223 201V121Z" stroke="#165BB6" stroke-miterlimit="10"/>
        <path d="M140.156 241L70.8223 201V281L140.156 321V241Z" stroke="#165BB6" stroke-miterlimit="10"/>
        <path d="M70.8223 201L1.66675 161V241L70.8223 281V201Z" stroke="#165BB6" stroke-miterlimit="10"/>
        <path d="M70.8223 41L140.156 81L70.8223 121L1.66675 81L70.8223 41Z" stroke="#165BB6" stroke-miterlimit="10"/>
        <path d="M209.489 41L278.645 81L209.489 121L140.156 81L209.489 41Z" stroke="#101010" stroke-miterlimit="10"/>
        <path d="M140.156 1L209.489 41L140.156 81L70.8223 41L140.156 1Z" stroke="#101010" stroke-miterlimit="10"/>
        <path d="M140.156 161L209.489 121V201L140.156 241V161Z" stroke="#165BB6" stroke-miterlimit="10"/>
        <path d="M209.489 121L278.645 81V161L209.489 201V121Z" stroke="#101010" stroke-miterlimit="10"/>
        <path d="M140.156 241L209.489 201V281L140.156 321V241Z" stroke="#165BB6" stroke-miterlimit="10"/>
        <path d="M209.489 201L278.645 161V241L209.489 281V201Z" stroke="#101010" stroke-miterlimit="10"/>
    </svg>

</template>

<script>
export default {
    name: 'Cube'
};
</script>

<style scoped>

</style>
