import { ElDialog, ElScrollbar } from 'element-plus';
import 'element-plus/es/components/dialog/style/index'
import 'element-plus/es/components/scrollbar/style/index'

import Close from '@/components/Partials/Icons/Close.vue';
export default {
  name: 'DetailModal',
  components: {
    Close,
    ElDialog,
    ElScrollbar
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    imageSize: {
      type: String,
      default: null
    },
    imagePosition: {
      type: String,
      default: null
    },
    content: {
      type: Function
    }
  },
  emits: ['closeModal', 'closedModal'],
  data() {
    return {
      showModal: false
    };
  },
  watch: {
    show(value) {
      this.showModal = value;
    }
  },
  mounted() {},
  methods: {
    handleCloseModal() {
      this.$emit('closeModal');
    },
    handleClosedModal() {
      this.$emit('closedModal');
    },
    handleOpenModal() {}
  }
};