<template>
    <svg width="267" height="318" viewBox="0 0 267 318" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_280_86)">
            <path d="M206 1L146 33L206 65L266 33L206 1Z" fill="white" stroke="black"/>
            <path d="M206 65L146 33V209L206 241V65Z" fill="white" stroke="black"/>
            <path d="M266 33L206 65V241L266 209V33Z" fill="white" stroke="black"/>
            <path d="M134 79L74 111L134 143L194 111L134 79Z" fill="white" stroke="#165BB6"/>
            <path d="M134 143L74 111V247L134 279V143Z" fill="white" stroke="#165BB6"/>
            <path d="M194 111L134 143V279L194 247V111Z" fill="white" stroke="#165BB6"/>
            <path d="M62 157L2 189L62 221L122 189L62 157Z" fill="white" stroke="black"/>
            <path d="M62 221L2 189V285L62 317V221Z" fill="white" stroke="black"/>
            <path d="M122 189L62 221V317L122 285V189Z" fill="white" stroke="black"/>
        </g>
        <defs>
            <clipPath id="clip0_280_86">
                <rect width="267" height="318" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: 'Chart'
};
</script>

<style scoped>

</style>
