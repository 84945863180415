<template>
    <div class="collapse-item">
        <div class="collapse-item__title">
            <h3>{{ title }}</h3>
        </div>
        <div class="collapse-item__content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CollapseItem',
    props: {
        title: {
            type: String
        },
        collapsed: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped lang="scss">
.collapse-item {
    background-color: #FFFFFF;
    border-radius: 12px;
    &__title {
        font-size: 24px;
        color: #101010;
        font-weight: 600;
        padding: 20px 0;
        margin: 0 20px;
        border-bottom: 2px solid #CDCDCDFF;
    }
    &__content {
        padding: 20px;
        max-width: 768px;
    }
}
</style>
