<template>
    <svg width="100%" viewBox="0 0 1022 178" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 176.257V88.1287V0H35.9575L116.493 119.248V0H151.711V176.257H116.739L35.2187 58.2545V176.257H0Z" fill="#101010"/>
        <path d="M242.991 178C229.363 178 217.787 175.262 208.264 169.785C198.741 164.308 191.435 156.59 186.345 146.632C181.255 136.508 178.71 124.724 178.71 111.281C178.71 97.6718 181.337 85.8051 186.591 75.6811C191.845 65.3911 199.234 57.3417 208.757 51.5329C218.444 45.724 229.855 42.8196 242.991 42.8196C252.842 42.8196 261.462 44.4792 268.85 47.7986C276.403 51.1179 282.724 55.765 287.814 61.7398C293.068 67.7147 296.927 74.6853 299.39 82.6517C302.017 90.4522 303.248 98.9165 303.084 108.045C303.084 110.368 303.002 112.609 302.838 114.766C302.674 116.924 302.345 119.082 301.853 121.239H212.944C213.436 126.218 214.832 130.782 217.131 134.931C219.429 139.081 222.631 142.4 226.736 144.889C231.005 147.213 236.259 148.375 242.498 148.375C246.603 148.375 250.379 147.96 253.827 147.13C257.275 146.134 260.23 144.641 262.693 142.649C265.156 140.657 266.88 138.334 267.865 135.678H301.853C299.718 145.47 295.695 153.52 289.785 159.827C284.038 166.133 277.06 170.78 268.85 173.768C260.641 176.589 252.021 178 242.991 178ZM213.683 96.8419H270.082C269.918 92.3608 268.768 88.2116 266.634 84.3944C264.664 80.4112 261.626 77.2578 257.521 74.9343C253.581 72.4448 248.491 71.2 242.252 71.2C236.012 71.2 230.758 72.4448 226.489 74.9343C222.385 77.4238 219.265 80.6601 217.131 84.6433C215.16 88.4606 214.011 92.5268 213.683 96.8419Z" fill="#101010"/>
        <path d="M310.825 176.257L356.141 109.289L313.041 45.0601H353.186L378.799 86.386L403.92 45.0601H442.833L399.487 110.036L445.789 176.257H405.644L377.322 133.189L349.245 176.257H310.825Z" fill="#101010"/>
        <path d="M513.657 178C499.045 178 488.208 173.851 481.148 165.552C474.252 157.254 470.804 145.885 470.804 131.446V76.179H451.348V45.0601H470.804V9.70908H505.284V45.0601H544.689V76.179H505.284V124.227C505.284 128.542 505.776 132.276 506.762 135.429C507.911 138.583 509.717 141.072 512.18 142.898C514.807 144.558 518.337 145.387 522.77 145.387C526.711 145.387 529.994 144.807 532.621 143.645C535.413 142.317 537.711 140.906 539.517 139.413L550.354 166.299C546.906 169.121 543.047 171.444 538.779 173.27C534.674 174.93 530.487 176.091 526.218 176.755C521.949 177.585 517.762 178 513.657 178Z" fill="#101010"/>
        <path d="M605.737 178C599.826 178 594.08 177.253 588.497 175.759C583.079 174.1 578.235 171.61 573.966 168.291C569.698 164.972 566.332 160.822 563.869 155.843C561.406 150.864 560.174 145.055 560.174 138.417C560.174 129.455 561.981 122.318 565.593 117.007C569.205 111.53 574.049 107.381 580.124 104.559C586.199 101.738 593.177 99.8294 601.058 98.8336C609.103 97.8378 617.395 97.3399 625.933 97.3399H644.65C644.65 92.5268 643.747 88.2946 641.941 84.6433C640.299 80.8261 637.754 77.9217 634.306 75.9301C630.858 73.7725 626.425 72.6937 621.007 72.6937C617.559 72.6937 614.193 73.1916 610.909 74.1874C607.79 75.0172 605.163 76.262 603.028 77.9217C600.894 79.5813 599.498 81.7389 598.841 84.3944H563.869C564.854 77.0918 567.153 70.868 570.765 65.7231C574.377 60.4121 578.892 56.097 584.31 52.7776C589.729 49.4583 595.639 47.0517 602.043 45.558C608.61 43.8984 615.342 43.0685 622.238 43.0685C641.12 43.0685 655.076 48.5454 664.107 59.4993C673.137 70.2872 677.652 85.6392 677.652 105.555V176.257H646.62L646.128 159.329C642.023 165.138 637.344 169.37 632.09 172.025C627 174.681 622.074 176.34 617.313 177.004C612.551 177.668 608.693 178 605.737 178ZM613.618 150.366C619.365 150.366 624.619 149.205 629.381 146.881C634.142 144.558 637.918 141.404 640.71 137.421C643.501 133.438 644.896 128.957 644.896 123.978V120.741H621.253C617.805 120.741 614.439 120.907 611.155 121.239C608.036 121.571 605.162 122.235 602.535 123.231C600.073 124.061 598.102 125.471 596.625 127.463C595.147 129.455 594.408 132.11 594.408 135.429C594.408 138.749 595.229 141.57 596.871 143.894C598.677 146.051 601.058 147.711 604.013 148.873C606.969 149.869 610.17 150.366 613.618 150.366Z" fill="#101010"/>
        <path d="M733.31 119.745C718.398 119.745 706.31 107.657 706.31 92.7455V88.3776H760.292C776.861 88.3776 790.292 101.809 790.292 118.378V119.745H733.31Z" fill="#101010"/>
        <path d="M823.995 176.257V35.2188C823.995 15.768 839.763 0 859.214 0V176.257H823.995Z" fill="#101010"/>
        <path d="M933.091 176.257V33.1105H913.004C894.718 33.1105 879.894 18.2864 879.894 0H1022C1022 18.2864 1007.18 33.1105 988.889 33.1105H968.556V176.257H933.091Z" fill="#101010"/>
    </svg>

</template>

<script>
export default {
    name: 'PromoTitle'
};
</script>

<style scoped>

</style>
