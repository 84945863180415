<template>
    <svg viewBox="0 0 426 253" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M116.344 200.471L117.094 200.905V200.039V151.389V151.099L116.842 150.955L62.0817 119.585L116.838 89.0086L117.094 88.8655V88.5721V40.9917V40.1199L116.341 40.5601L0.747512 108.188L0.5 108.333V108.62V133.212V133.501L0.749752 133.645L116.344 200.471ZM309.173 199.771V200.646L309.927 200.202L425.254 132.306L425.5 132.161V131.876V107.283V106.995L425.25 106.85L309.923 40.2913L309.173 39.8585V40.7244V89.1067V89.3977L309.426 89.5414L364.185 120.647L309.428 151.488L309.173 151.631V151.924V199.771Z" fill="white" stroke="#101010"/>
        <path d="M146.052 251.837L145.824 252.5H146.525H192.709H193.065L193.181 252.163L279.944 1.16335L280.173 0.5H279.471H233.02H232.663L232.547 0.8371L146.052 251.837Z" fill="white" stroke="#165BB6"/>
    </svg>

</template>

<script>
export default {
    name: 'CodeIcon'
};
</script>

<style scoped>

</style>
