<template>
    <div class="input-field" :class="{ 'input-field_error': showError }">
        <input class="input-field__field"
               v-bind="$attrs"
               :value="modelValue"
               @input="$emit('update:modelValue', $event.target.value)" />

        <div v-if="showError"
             class="input-field__error">
            {{ error }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputField',
    emits: [
        'update:modelValue'
    ],
    props: {
        modelValue: {
            type: String
        },
        title: {
            type: String
        },
        size: {
            type: String,
            default: 'default' // default or large
        },
        showError: {
            type: Boolean,
            default: false
        },
        error: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.input-field {

    &__field {
        padding: 18px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0.25);
        border: 1px solid transparent;
        color: rgba(255, 255, 255, 0.8);
        font-weight: 400;
        font-size: 18px;
        width: 100%;
        box-sizing: border-box;
        &::placeholder {
            color: #ddedf7;
        }
        &:focus-visible {
            outline: none;
            background-color: rgba(255, 255, 255, 0.30);
            border: 1px solid rgba(255, 255, 255, 0.25);
            color: #FFFFFF;
        }

    }
    &__error {
        padding: 5px 0 0 2px;
        color: rgb(167 53 53 / 80%);
    }
    &_error {
        .input-field__field {
            background-color: rgb(95 29 29 / 20%);
            border: 1px solid rgb(169 36 36);
        }

    }
}
</style>
