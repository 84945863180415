<template>
    <CardWrapper class="info-block"
                 @click="clickEvent"
                 :class="{ 'info-block_clickable': clickable && !isMobileDevice() }"
                 :min-height="minHeight"
                 :max-height="maxHeight">
        <div class="info-block__title"
             :style="{ 'font-size': titleFontSize }">
            <h3>{{ title }}</h3>
        </div>
        <div v-if="description"
             class="info-block__subscription">
            {{ description }}
        </div>
        <div v-if="$slots.content"
             class="info-block__content">
            <slot name="content" />
        </div>
        <div v-if="image"
             class="info-block__image"
             :style="`background-image: url(${image}); background-position: ${imagePosition}; background-size: ${imageSize}; transform: rotate(${imageRotate}deg)`">
        </div>
    </CardWrapper>
</template>

<script>
import CardWrapper from '@/components/Partials/CardWrapper.vue';

export default {
    name: 'InfoBlock',
    components: { CardWrapper },
    props: {
        title: {
            type: String
        },
        titleFontSize: {
            type: String,
            default: '24px'
        },
        description: {
            type: String
        },
        image: {
            type: String
        },
        imagePosition: {
            type: String
        },
        imageSize: {
            type: String,
            default: 'contain'
        },
        imageRotate: {
            type: Number,
            default: 0
        },
        maxHeight: {
            type: String,
            default: 'auto'
        },
        minHeight: {
            type: String,
            default: 'auto'
        },
        clickable: {
            type: Boolean,
            default: false
        },
        click: {
            type: Function
        }
    },
    methods: {
        clickEvent () {
            if (this.clickable && this.click) {
                this.click(
                    this.title,
                    this.image,
                    this.imageSize,
                    this.imagePosition,
                    this.$slots.detail
                );
            }
        }
    }
};
</script>

<style scoped lang="scss">
.info-block {
    box-sizing: border-box;
    height: 100%;
    position: relative;

    &_clickable {
        cursor: pointer;
        transition: transform  0.15s;

        &:hover {
            transform: translateY(-20px);
        }
    }

    &__title {
        font-weight: 500;
        color: #FFFFFF;
        line-height: 31px;
        padding: 30px 20px 0 20px;
        z-index: 2;
        position: relative;
        width: 90%;
    }
    &__subscription {
        margin: 15px 0 0 0;
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
        padding: 0 20px;
        z-index: 2;
        position: relative;
    }
    &__image {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        z-index: 1;
    }
    &__content {
        margin: 20px;
        padding: 20px 0;
        border-top: 1px solid rgb(255, 255, 255, 0.3);
        z-index: 1;
        position: relative;
    }
}
</style>
