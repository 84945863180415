<template>
    <header class="header">
        <LogoBlue class="header__logo" />
        <nav class="header__nav navigation">
            <a @click.prevent="goToAnchor('services')"
               href="#services"
               class="navigation__link"
               title="Услуги">
                Услуги
            </a>
            <a @click.prevent="goToAnchor('cases')"
               href="#cases"
               class="navigation__link"
               title="Услуги">
                Кейсы
            </a>
            <a @click.prevent="goToAnchor('services-cost')"
               href="#services-cost"
               class="navigation__link"
               title="Стоимость услуг">
                Стоимость услуг
            </a>
            <a @click.prevent="goToAnchor('feedback')"
               href="#feedback"
               class="navigation__link"
               title="Контакты">
                Контакты
            </a>
        </nav>
        <Btn title="Связаться с нами"
             @click="goToAnchor('feedback')" />
    </header>
</template>

<script>
import LogoBlue from '@/components/Partials/LogoBlue.vue';
import Btn from '@/components/Partials/Btn.vue';

export default {
    name: 'HeaderComponent',
    components: {
        Btn,
        LogoBlue
    }
};
</script>

<style scoped lang="scss">
.header {
    //height: 64px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1920px;
    margin: 0 auto;
    &__logo {
        width: 120px;
        height: 22px;
    }
    &__nav {

    }
}
.navigation {
    display: none;
    gap: 0 30px;

    @media (min-width: 768px) {
        display: flex;
    }

    &__link {
        color: #101010;
        cursor: pointer;
        text-decoration: none;
        font-weight: 400;
        font-size: 18px;
        &:visited {
            color: #101010;
        }
        &:hover {
            color: #4D96E5;
        }
    }
}
</style>
