<template>
    <div class="feedback-form">
        <div v-if="loading"
             class="feedback-form__loading">
            <Spinner width="70" height="70" />
        </div>
        <div class="feedback-form__rectangle"></div>
        <div class="feedback-form__content">
            <div class="feedback-form__left">
                <div class="feedback-form__title">
                    <h2>{{ title }}</h2>
                </div>
                <div class="feedback-form__contacts">
                    <CompanyInfo header="Общество с ограниченной ответственностью «Некста»" />
                </div>
            </div>
            <div class="feedback-form__right">
                <form method="post" class="feedback-form__form form">
                    <InputField :model-value="form.name"
                                @update:model-value="trimValue($event, 'name')"
                                :disabled="sent"
                                :show-error="validationErrors.name.show"
                                :error="validationErrors.name.message"
                                placeholder="Имя" />
                    <InputField :model-value="form.email"
                                @update:model-value="trimValue($event, 'email')"
                                :disabled="sent"
                                type="email"
                                :show-error="validationErrors.email.show"
                                :error="validationErrors.email.message"
                                placeholder="Email" />
                    <InputField :model-value="form.phone"
                                @update:model-value="trimValue($event, 'phone')"
                                :disabled="sent"
                                placeholder="Телефон (не обязательно)" />
                    <TextAreaField :model-value="form.comment"
                                   @update:model-value="trimValue($event, 'comment')"
                                   :disabled="sent"
                                   placeholder="Комментарий (не обязательно)" />
                    <CheckboxField v-model="form.approval"
                                   :disabled="sent"
                                   :show-error="validationErrors.approval.show"
                                   :error="validationErrors.approval.message">
                        <template #label>
                            <a href="docs/consent_opd.docx"
                               target="_blank"
                               title="Согласие на обработку персональных данных">
                                Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных, в соответствии с Федеральным законом от 27.07.2006 года №152-ФЗ «О персональных данных», на условиях и для целей, определенных в Согласии на обработку персональных данных.
                            </a>
                        </template>
                    </CheckboxField>
                    <Btn class="form__button"
                         :type="sent ? 'success' : 'secondary'"
                         :title="sent ? 'Отправлено!' : 'Отправить'"
                         @click.prevent="send"
                         size="large" />
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Btn from '@/components/Partials/Btn.vue';
import InputField from '@/components/Partials/Fields/InputField.vue';
import TextAreaField from '@/components/Partials/Fields/TextAreaField.vue';
import CheckboxField from '@/components/Partials/Fields/CheckboxField.vue';
import Spinner from '@/components/Partials/Icons/Spinner.vue';
import axios from 'axios';
import CompanyInfo from '@/components/Partials/CompanyInfo.vue';

export default {
    name: 'FeedbackForm',
    components: { CompanyInfo, Spinner, CheckboxField, TextAreaField, InputField, Btn },
    props: {
        title: {
            type: String
        }
    },
    data () {
        return {
            loading: false,
            sent: false,
            form: {
                name: null,
                email: null,
                phone: null,
                comment: null,
                approval: false
            },
            validationErrors: {
                name: {
                    show: false,
                    message: 'Обязательно укажите имя'
                },
                email: {
                    show: false,
                    message: null
                },
                approval: {
                    show: false,
                    message: 'Необходимо согласие'
                }
            }
        };
    },
    methods: {
        trimValue (value, item) {
            value = value.trimStart();
            this.form[item] = value;
        },
        resetValidation () {
            for (const error in this.validationErrors) {
                const obj = this.validationErrors[error];
                obj.show = false;
            }
        },
        emailValidate (email) {
            const regExp = /\S+@\S+\.\S+/;
            return regExp.test(email);
        },
        validation () {
            return new Promise((resolve, reject) => {
                const form = this.form;
                const errors = [];

                for (const item in form) {
                    const value = form[item];
                    if (item === 'name' || item === 'approval') {
                        if (!value) {
                            errors.push(item);
                        }
                    }
                    if (item === 'email') {
                        if (!value) {
                            this.validationErrors.email.message = 'Обязательно укажите электронную почту';
                            errors.push(item);
                        } else if (!this.emailValidate(value)) {
                            this.validationErrors.email.message = 'Укажите корректный email';
                            errors.push(item);
                        }
                    }
                }

                if (errors.length === 0) {
                    resolve();
                } else {
                    for (let i = 0; i < errors.length; i++) {
                        const name = errors[i];
                        this.validationErrors[name].show = true;
                    }
                    reject(new Error('Ошибка валидации.'));
                }
            });
        },
        send () {
            if (this.sent) {
                return;
            }
            this.resetValidation();
            this.validation()
                .then(() => {
                    this.loading = true;
                    const form = this.form;
                    axios.post('api/web_hook.php', {
                        form_data: form
                    }).then(() => {
                        this.sent = true;
                    }).catch((error) => {
                        console.error(error);
                    }).finally(() => {
                        this.loading = false;
                    });
                });
        }
    }
};
</script>

<style scoped lang="scss">
.feedback-form {
    background: #458BE8 linear-gradient(135deg, #2AFADF 0%, #4C83FF 100%);
    border-radius: 12px;
    position: relative;
    overflow: hidden;

    &__loading {
        z-index: 10;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255,255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        display: flex;
        position: relative;
        padding: 60px;
        gap: 0 60px;
        @media (max-width: 1024px) {
            flex-direction: column;
            padding: 30px;
        }
    }

    &__left, &__right {
        position: relative;
        width: 100%;
        @media (min-width: 1024px) {
            width: 50%;
        }
    }
    &__contacts {
        position: absolute;
        bottom: 0;

        @media (max-width: 1024px) {
            position: relative;
            margin: 60px 0;
        }
    }
    &__rectangle {
        background-image: url(@/assets/blue_bg.jpg);
        opacity: 0.3;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-size: cover;
    }
    &__title {
        position: relative;
        font-size: 36px;
        font-weight: 600;
        color: #FFFFFF;
        z-index: 1;

        @media (min-width: 1024px) {
            font-size: 46px;
        }
    }
    &__form {
        z-index: 1;
        width: 100%;
    }
}
.form {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    box-sizing: border-box;
    &__button {
        margin: 30px 0 0 0;
    }
}
</style>
