<template>
    <div class="simple-info-block">
        <div class="simple-info-block__icon-section">
            <div class="simple-info-block__icon">
                <slot name="icon" />
            </div>
        </div>
        <div class="simple-info-block__title">
            <h3>{{ title }}</h3>
        </div>
        <div class="simple-info-block__description">
            {{ description }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'SimpleInfoBlock',
    props: {
        title: {
            type: String
        },
        description: {
            type: String
        }
    }
};
</script>

<style scoped lang="scss">
.simple-info-block {
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 40px;
    box-sizing: border-box;
    height: 100%;

    &__icon-section {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        width: 100%;
        height: 300px;
        max-width: 370px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__title {
        margin: 60px 0 0 0;
        font-size: 36px;
        font-weight: 600;
        color: #101010;
    }
    &__description {
        margin: 20px 0 0 0;
        font-size: 20px;
        font-weight: 400;
        color: #5A5A5A;
    }
}
</style>
