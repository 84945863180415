<template>
    <div class="main-promo">
<!--        <div class="main-promo__wave">-->
<!--            <PromoWave />-->
<!--        </div>-->
        <div class="main-promo__title">
            <PromoTitle />
        </div>
        <div class="main-promo__description">
            <h1>Наша цель — сделать ваш бизнес более эффективным и успешным с помощью современных технологий!</h1>
        </div>
    </div>
</template>

<script>
import PromoTitle from '@/components/Partials/PromoTitle.vue';

export default {
    name: 'MainPromo',
    components: { PromoTitle }
};
</script>

<style scoped lang="scss">
.main-promo {
    padding: 0 24px;
    //border-radius: 24px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap: 50px 0;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;

    @media (min-width: 768px) {
        height: 768px;
    }

    &__wave {
        position: absolute;
        width: 100%;
        max-width: 1872px;
        max-height: 623px;
        z-index: 0;
        bottom: 0;
        left: 0;
    }
    &__title {
        width: 100%;
        max-width: 1022px;
        max-height: 180px;
        z-index: 1;
    }
    &__description {
        max-width: 800px;
        text-align: center;
        color: #5A5A5A;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        z-index: 1;
    }
}
</style>
