<template>
    <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" viewBox="0 0 24 24">
        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m7 7l10 10M7 17L17 7"></path>
    </svg>
</template>

<script>
export default {
    name: 'Close'
};
</script>

<style scoped lang="scss">

</style>
