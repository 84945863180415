<template>
    <svg width="273" height="50" viewBox="0 0 273 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1818 0C8.14028 0 0 8.14028 0 18.1818V50H50C60.0415 50 68.1818 41.8597 68.1818 31.8182V0H18.1818ZM54.5455 40.9091C57.0558 40.9091 59.0909 38.874 59.0909 36.3636L59.0909 9.09091L47.7273 9.09091V25L34.0909 25V22.7273C34.0909 15.1961 27.9857 9.09091 20.4545 9.09091H13.6364C11.126 9.09091 9.09091 11.126 9.09091 13.6364V40.9091H20.4545V25L34.0909 25V27.2727C34.0909 34.8039 40.1961 40.9091 47.7273 40.9091H54.5455Z" fill="white"/>
        <path d="M88.6365 40.5975V24.8442V9.09082H95.1134L109.62 30.4068V9.09082H115.964V40.5975H109.664L94.9803 19.504V40.5975H88.6365Z" fill="white"/>
        <path d="M132.406 40.909C129.951 40.909 127.866 40.4195 126.151 39.4405C124.435 38.4615 123.119 37.0819 122.202 35.3019C121.286 33.4922 120.827 31.3858 120.827 28.9827C120.827 26.55 121.3 24.4288 122.247 22.6191C123.193 20.7797 124.524 19.3409 126.239 18.3025C127.984 17.2642 130.04 16.745 132.406 16.745C134.18 16.745 135.733 17.0417 137.064 17.635C138.424 18.2283 139.563 19.059 140.48 20.1271C141.426 21.1951 142.121 22.4411 142.565 23.8651C143.038 25.2595 143.26 26.7725 143.23 28.4042C143.23 28.8196 143.216 29.2201 143.186 29.6058C143.156 29.9914 143.097 30.3771 143.009 30.7628H126.994C127.082 31.6528 127.334 32.4687 127.748 33.2103C128.162 33.952 128.739 34.5454 129.478 34.9904C130.247 35.4057 131.193 35.6134 132.317 35.6134C133.057 35.6134 133.737 35.5392 134.358 35.3909C134.979 35.2129 135.511 34.9459 135.955 34.5899C136.399 34.2339 136.709 33.8185 136.887 33.3438H143.009C142.624 35.0942 141.9 36.5331 140.835 37.6604C139.8 38.7878 138.543 39.6185 137.064 40.1525C135.585 40.6568 134.033 40.909 132.406 40.909ZM127.127 26.4017H137.286C137.256 25.6007 137.049 24.859 136.665 24.1766C136.31 23.4646 135.763 22.901 135.023 22.4856C134.313 22.0406 133.397 21.8181 132.273 21.8181C131.149 21.8181 130.203 22.0406 129.434 22.4856C128.694 22.9306 128.132 23.5091 127.748 24.2211C127.393 24.9035 127.186 25.6303 127.127 26.4017Z" fill="white"/>
        <path d="M144.625 40.5975L152.787 28.6267L145.024 17.1455H152.255L156.869 24.5326L161.394 17.1455H168.403L160.595 28.7602L168.936 40.5975H161.704L156.603 32.8988L151.545 40.5975H144.625Z" fill="white"/>
        <path d="M181.161 40.909C178.528 40.909 176.576 40.1673 175.305 38.684C174.063 37.2006 173.441 35.1684 173.441 32.5873V22.7081H169.937V17.1455H173.441V10.8264H179.652V17.1455H186.75V22.7081H179.652V31.2968C179.652 32.0681 179.741 32.7357 179.918 33.2993C180.125 33.863 180.451 34.308 180.894 34.6344C181.368 34.931 182.003 35.0794 182.802 35.0794C183.512 35.0794 184.103 34.9755 184.577 34.7679C185.079 34.5305 185.493 34.2784 185.819 34.0114L187.771 38.8175C187.15 39.3218 186.455 39.7371 185.686 40.0635C184.946 40.3602 184.192 40.5678 183.423 40.6865C182.654 40.8348 181.9 40.909 181.161 40.909Z" fill="white"/>
        <path d="M197.747 40.909C196.682 40.909 195.647 40.7755 194.641 40.5085C193.665 40.2118 192.793 39.7668 192.024 39.1735C191.255 38.5801 190.649 37.8384 190.205 36.9484C189.761 36.0584 189.54 35.02 189.54 33.8333C189.54 32.2313 189.865 30.9556 190.516 30.0063C191.166 29.0272 192.039 28.2856 193.133 27.7812C194.227 27.2769 195.484 26.9357 196.904 26.7577C198.353 26.5797 199.847 26.4907 201.384 26.4907H204.756C204.756 25.6303 204.593 24.8738 204.268 24.2211C203.972 23.5388 203.514 23.0196 202.893 22.6636C202.272 22.2779 201.473 22.0851 200.497 22.0851C199.876 22.0851 199.27 22.1741 198.678 22.3521C198.116 22.5004 197.643 22.7229 197.259 23.0196C196.874 23.3163 196.623 23.702 196.505 24.1766H190.205C190.383 22.8713 190.797 21.7588 191.447 20.8391C192.098 19.8897 192.911 19.1184 193.887 18.525C194.863 17.9317 195.928 17.5015 197.081 17.2345C198.264 16.9378 199.477 16.7895 200.719 16.7895C204.12 16.7895 206.634 17.7685 208.261 19.7265C209.887 21.6549 210.701 24.3991 210.701 27.9592V40.5975H205.111L205.022 37.5714C204.283 38.6098 203.44 39.3663 202.494 39.841C201.577 40.3157 200.689 40.6123 199.832 40.731C198.974 40.8497 198.279 40.909 197.747 40.909ZM199.166 35.9694C200.201 35.9694 201.148 35.7617 202.006 35.3464C202.863 34.931 203.543 34.3674 204.046 33.6553C204.549 32.9433 204.8 32.1423 204.8 31.2523V30.6738H200.542C199.921 30.6738 199.314 30.7035 198.723 30.7628C198.161 30.8221 197.643 30.9408 197.17 31.1188C196.726 31.2671 196.371 31.5193 196.105 31.8753C195.839 32.2313 195.706 32.706 195.706 33.2993C195.706 33.8927 195.854 34.397 196.15 34.8124C196.475 35.198 196.904 35.4947 197.436 35.7024C197.969 35.8804 198.545 35.9694 199.166 35.9694Z" fill="white"/>
        <path d="M221.47 30.4958C218.373 30.4958 215.863 27.9854 215.863 24.8887H225.383C228.48 24.8887 230.99 27.3991 230.99 30.4958H221.47Z" fill="white"/>
        <path d="M237.061 40.5975V15.4347C237.061 11.9311 239.901 9.09082 243.405 9.09082V40.5975H237.061Z" fill="white"/>
        <path d="M256.712 40.5975V15.0094H253.049C249.78 15.0094 247.13 12.3596 247.13 9.09082H272.727C272.727 12.3596 270.077 15.0094 266.809 15.0094H263.101V40.5975H256.712Z" fill="white"/>
    </svg>

</template>

<script>
export default {
    name: 'LogoWhite'
};
</script>

<style scoped>

</style>
