<template>
    <button :class="btnClass()">
        {{ title }}
    </button>
</template>

<script>
export default {
    name: 'Btn',
    props: {
        title: {
            type: String
        },
        type: {
            type: String,
            default: 'primary' // primary or secondary
        },
        size: {
            type: String,
            default: 'default' // default or large
        }
    },
    methods: {
        btnClass () {
            return {
                button: true,
                button_default: this.size === 'default',
                button_large: this.size === 'large',
                button_primary: this.type === 'primary',
                button_secondary: this.type === 'secondary',
                button_success: this.type === 'success'
            };
        }
    }
};
</script>

<style scoped lang="scss">
.button {
    border-radius: 8px;
    border: none;
    cursor: pointer;
    &_success {
        background-color: #25c345;
        color: #ffffff;
        &:hover {
            background-color: #24bb42;
        }
        &:active {
            background-color: #1fa039;
        }
    }
    &_primary {
        background-color: #1b69bc;
        color: #ffffff;
        &:hover {
            background-color: #4D96E5;
        }
        &:active {
            background-color: #134B86;
        }
    }
    &_secondary {
        background-color: #FFFFFF;
        color: rgba(27, 105, 188, 1);
        &:hover {
            background-color: #e5e5e5;
        }
        &:active {
            background-color: #cdcdcd;
        }
    }
    &_default {
        padding: 8px 12px;
        font-size: 16px;
        font-weight: 400;
    }
    &_large {
        padding: 18px 16px;
        font-size: 22px;
        font-weight: 500;
    }

}
</style>
