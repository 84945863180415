<template>
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.166992 4.80175L4.48798 9.16683L11.8337 2.02397L10.5374 0.833496L4.48798 6.78588L1.38551 3.6827L0.166992 4.80175Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: 'Accept'
};
</script>

<style scoped>

</style>
